import axiosInstance from 'services/common/instance';

const getTokenWebSocketService = async (): Promise<string> => {
  const res = await axiosInstance.post('members/ws-token');
  return res.data.data.wsToken;
};

export const getTokenWebSocketForGuestService = async (): Promise<string> => {
  const res = await axiosInstance.post('members/ws-token-for-guest');
  return res.data.data.wsToken;
};

export default getTokenWebSocketService;
