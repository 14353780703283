import { GeneralDataSystem } from './types';

import axiosInstance from 'services/common/instance';

const getSystemService = async (
  ref?: string,
): Promise<GeneralDataSystem> => {
  const res = await axiosInstance.get('systems/general', { params: { ref } });
  return res.data.data;
};

export default getSystemService;
