import React from 'react';

import mapModifiers from 'utils/functions';

type Variant =
  | 'fullScreen'
  | 'noPaddingRight'
  | 'noPaddingLeft';

interface ContainerProps {
  variant?: Variant;
  children?: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({ variant, children }) => (
  <div
    className={`container ${mapModifiers(
      'o-container',
      variant,
    )}`}
  >
    {children}
  </div>
);

Container.defaultProps = {
  variant: undefined,
};

export default Container;
