/* eslint-disable */
import Hls from 'hls.js';
import React, {
  useEffect, useMemo, useRef, useState
} from 'react';

import Icon from 'components/atoms/Icon';
import useMutedAutoplay from 'hooks/useMutedAutoplay';
import mapModifiers from 'utils/functions';

// const list = [
//   {
//     src: kvImage,
//     type: 'image',
//   },
//   // {
//   //   src: 'https://cdn-mecury.3forcom.org/static/castrol-2024/clip/phase2_clip6.mp4',
//   //   type: 'video',
//   // },
//   // {
//   //   src: 'https://cdn-mecury.3forcom.org/static/castrol-2024/clip/castrol_simple_social.mp4',
//   //   type: 'video',
//   // },
//   // {
//   //   src: 'https://cdn-mecury.3forcom.org/static/castrol-2024/clip/tvc4.mp4',
//   //   type: 'video',
//   // },
//   // {
//   //   src: 'https://cdn-mecury.3forcom.org/static/castrol-2024/clip/clip3_dathang.mp4',
//   //   type: 'video',
//   // },
//   // {
//   //   src: 'https://cdn-mecury.3forcom.org/static/castrol-2024/clip/clip2_thuthap.mp4',
//   //   type: 'video',
//   // },
//   // {
//   //   src: 'https://cdn-mecury.3forcom.org/static/castrol-2024/clip/clip_key_highlight.mp4',
//   //   type: 'video',
//   // },
// ];

interface KvClipProps {
  list: Array<{
    src: string,
    type: 'video' | 'image'
  }>;
  isLoop?: boolean;
  onHandleEnd?: () => void;
  timeMoveEnd?: number;
  timeMoveNext?: number;
  voiceCenter?: boolean;
}

const KvClip: React.FC<KvClipProps> = ({
  list,
  isLoop,
  timeMoveEnd = 7000,
  timeMoveNext = 3000,
  voiceCenter,
  onHandleEnd,
}) => {
  const [indexCurrent, setIndexCurrent] = useState(0);
  const hlsRef = useRef<Hls>();
  const [muted, setMuted] = useState(true);

  const playersRef = useRef(list.reduce((prev: Array<{ type: 'video' | 'image', ref: React.RefObject<HTMLVideoElement | HTMLImageElement> }>, cur) => {
    if (cur.type === 'video') {
      prev.push({ type: 'video', ref: React.createRef<HTMLVideoElement>() });
    } else {
      prev.push({ type: 'image', ref: React.createRef<HTMLImageElement>() });
    }
    return prev;
  }, []));

  if (playersRef.current[0] && playersRef.current[0].type === 'video') {
    useMutedAutoplay(playersRef.current[0].ref as React.RefObject<HTMLVideoElement>, setMuted);
  }

  const audioTrackVideo = (playerRef?: React.RefObject<HTMLVideoElement>) => {
    const player = playerRef?.current;

    if (player) {
      const hls = hlsRef.current;
      const { audioTracks } = player as any;

      if (hls) {
        hls.once(Hls.Events.MANIFEST_LOADED, () => {
          hls.audioTrack = Math.max(
            hls.audioTracks.findIndex((t) => t.lang === 'vi'),
            0,
          );
        });
      } else if (audioTracks) {
        const onTrackAdd = () => {
          if (audioTracks.length > 1) {
            Array.from(audioTracks).forEach((t: any) => {
              // eslint-disable-next-line no-param-reassign
              t.enabled = t.language === 'vi';
            });
          }
        };

        audioTracks.addEventListener('addtrack', onTrackAdd);

        return () => audioTracks.removeEventListener('addtrack', onTrackAdd);
      }
    }
  };

  /**
   * change audioLang to default on start-up only
   */
  useEffect(() => {
    playersRef.current.forEach((playerRef) => {
      HTMLVideoElement
      if (playerRef.type === 'video') {
        audioTrackVideo(playerRef.ref as React.RefObject<HTMLVideoElement>);
      }
    });

    return () => {
      /* empty */
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    playersRef.current.forEach((item, index) => {
      if (item && item.type === 'video' && item.ref.current) {
        (item.ref.current as HTMLVideoElement).currentTime = 0;
        if (index === indexCurrent) {
          (item.ref.current as HTMLVideoElement)?.play();
        } else {
          (item.ref.current as HTMLVideoElement)?.pause();
        }
      } else {
        if (index === indexCurrent) {
          if (playersRef.current.length - 1 === indexCurrent && !isLoop) { // last item
            setTimeout(() => {
              if (onHandleEnd) {
                onHandleEnd();
              }
            }, timeMoveEnd);
          } else {
            setTimeout(() => {
              if (playersRef.current[indexCurrent + 1]) {
                if (playersRef.current[indexCurrent + 1]?.type === 'video') {
                  (playersRef.current[indexCurrent + 1]?.ref?.current as HTMLVideoElement)?.play();
                }
                setIndexCurrent(indexCurrent + 1);
              }
            }, timeMoveNext);
          }
        }
      }
    });

    return () => {
      playersRef.current.forEach((video) => {
        if (video && video.type === 'video' && video.ref.current) {
          (video.ref.current as HTMLVideoElement)?.pause();
          (video.ref.current as HTMLVideoElement).currentTime = 0;
        }
      });
    }
  }, [playersRef, indexCurrent, timeMoveEnd, timeMoveNext]);

  const iconSoundCurrent = useMemo(() => {
    if (muted) return 'mute';
    return 'sound';
  }, [indexCurrent, muted]);

  const hasVideo = useMemo(() => list.find((item) => item.type === 'video'), [list]);

  return (
    <div className="p-eventWaiting">
      {
        hasVideo && (
          <div
            className={mapModifiers('t-livestream_sound', voiceCenter && muted ? 'centerScreen' : '')}
            onClick={() => setMuted(!muted)}
          >
            <Icon iconName={iconSoundCurrent} iconSize="24x24" />
          </div>
        )
      }
      {
        list.map((item, index) => {
          if (item.type === 'video') {
            return (
              <video
                className={mapModifiers('p-eventWaiting_video', indexCurrent === index ? 'show' : '')}
                src={item.src}
                autoPlay
                muted={muted}
                loop={false}
                playsInline
                disablePictureInPicture
                disableRemotePlayback
                x-webkit-airplay="deny"
                ref={playersRef.current[index].ref as React.RefObject<HTMLVideoElement>}
                onEnded={() => {
                  if (indexCurrent === list.length - 1) {
                    if (isLoop) {
                      setIndexCurrent(0);
                      if (playersRef.current[0].type === 'video') {
                        (playersRef.current[0].ref.current as HTMLVideoElement)?.play();
                      }
                    } else {
                      if (onHandleEnd) {
                        onHandleEnd();
                      }
                    }
                  } else {
                    setIndexCurrent(indexCurrent + 1);
                  }
                  if (muted) {
                    setMuted(true);
                  } else {
                    setMuted(false);
                  }
                }}
              />
            );
          }
          return (
            <img
              src={item.src}
              alt="KV"
              className={mapModifiers('p-eventWaiting_image', indexCurrent === index ? 'show' : '')}
            />
          );
        })
      }
    </div>
  );
};

KvClip.defaultProps = {
};

export default KvClip;
