import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import getLiveStreamSessionsCurrentService from 'services/livestream';
import {
  ComboCurrentSessionTypes, LivestreamSessionsCurrentTypes,
  VoucherCurrentSessionTypes
} from 'services/livestream/types';
import { getVoucherCartOfMemberService } from 'services/member';

type EyeEventTypes = {
  total: number;
  fake: number;
};

type OrderEventTypes = {
  totalSkuSold: number;
  fake: number;
};

type StatisticsEventTypes = {
  eye: EyeEventTypes;
  order: OrderEventTypes;
};

// type LiveStreamEventTypes = {
//   created_at: string;
//   end_date: string;
//   id: number;
//   livestream_at: string;
//   name: string;
//   start_date: string;
//   status: StatusLiveStreamTypes;
//   updated_at: string;
// };
interface LivestreamState {
  onLive?: boolean;
  isLoading?: boolean;
  statistics: StatisticsEventTypes;
  // liveStreamData?: LiveStreamEventTypes;
  liveStreamSessionCurrent?: LivestreamSessionsCurrentTypes;
  needUpdateLivestreamSessionCurrent: boolean;
  comboSessionStatus?: ComboCurrentSessionTypes;
  is404ComboSession?: boolean;
  voucherSessionStatus?: VoucherCurrentSessionTypes;
  vouchersInCart?: VoucherCurrentSessionTypes;
  isRecallVoucherInCart?: boolean;
  isLoadingVoucherInCard?: boolean;
}

const initialState: LivestreamState = {
  // liveStreamData: undefined,
  statistics: {
    eye: {
      total: 0,
      fake: 0
    },
    order: {
      fake: 0,
      totalSkuSold: 0
    }
  },
  onLive: false,
  liveStreamSessionCurrent: undefined,
  needUpdateLivestreamSessionCurrent: false,
  comboSessionStatus: undefined,
  voucherSessionStatus: undefined,
  vouchersInCart: undefined,
  is404ComboSession: false,
  isLoading: false,
  isRecallVoucherInCart: false,
  isLoadingVoucherInCard: false,
};

export const getLiveStreamCurrentAction = createAsyncThunk<
  LivestreamSessionsCurrentTypes,
  void,
  { rejectValue: ErrorResponse }
>('liveSteamReducer/getLiveStreamCurrent', async (_, { rejectWithValue }) => {
  try {
    const res = await getLiveStreamSessionsCurrentService();
    return res;
  } catch (error) {
    return rejectWithValue(error as ErrorResponse);
  }
});

export const getVoucherInCartAction = createAsyncThunk<
  VoucherCurrentSessionTypes,
  void,
  { rejectValue: ErrorResponse }
>('liveSteamReducer/getVoucherInCartt', async (_, { rejectWithValue }) => {
  try {
    const res = await getVoucherCartOfMemberService();
    return res;
  } catch (error) {
    return rejectWithValue(error as ErrorResponse);
  }
});

export const liveStreamSlice = createSlice({
  name: 'liveSteam',
  initialState,
  reducers: {
    setStatusLiveStream: ($state, action: PayloadAction<LivestreamSessionsCurrentTypes>) => {
      $state.liveStreamSessionCurrent = action.payload;
      $state.needUpdateLivestreamSessionCurrent = true;
    },
    setNeedUpdateLivestreamSessionCurrent: ($state, action: PayloadAction<boolean>) => {
      $state.needUpdateLivestreamSessionCurrent = action.payload;
    },
    setStatisticsEvent: ($state, action: PayloadAction<StatisticsEventTypes>) => {
      $state.statistics = action.payload;
    },
    setOrderSessionEvent: ($state, action: PayloadAction<ComboCurrentSessionTypes | undefined>) => {
      if (action.payload?.status === 'stop' && $state.liveStreamSessionCurrent) {
        $state.liveStreamSessionCurrent = {
          ...$state.liveStreamSessionCurrent,
          hasComboReleased: true
        };
      }
      $state.comboSessionStatus = action.payload;
    },
    setVoucherSessionEvent: ($state, action: PayloadAction<VoucherCurrentSessionTypes
      | undefined>) => {
      $state.voucherSessionStatus = action.payload;
    },
    pushToOnLive: ($state, action: PayloadAction<boolean>) => {
      $state.onLive = action.payload;
    },
    setVoucherInCart: ($state, action: PayloadAction<VoucherCurrentSessionTypes>) => {
      $state.vouchersInCart = action.payload;
    },
    updateTotalOrder: ($state, action: PayloadAction<number>) => {
      if ($state.statistics) {
        $state.statistics.order.totalSkuSold = action.payload;
      }
    },
    setReCallVoucherInCart: ($state, action: PayloadAction<boolean>) => {
      $state.isRecallVoucherInCart = action.payload;
    },
    set404ForCombo: ($state, action: PayloadAction<boolean>) => {
      $state.is404ComboSession = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getLiveStreamCurrentAction.pending, ($state) => {
      $state.isLoading = true;
    });
    builder.addCase(getLiveStreamCurrentAction.fulfilled, ($state, action) => {
      $state.liveStreamSessionCurrent = action.payload;
      $state.isLoading = false;
    });
    builder.addCase(getLiveStreamCurrentAction.rejected, ($state) => {
      $state.isLoading = false;
    });
    builder.addCase(getVoucherInCartAction.pending, ($state) => {
      $state.isLoadingVoucherInCard = true;
    });
    builder.addCase(getVoucherInCartAction.fulfilled, ($state, action) => {
      $state.vouchersInCart = action.payload;
      $state.isLoadingVoucherInCard = false;
    });
    builder.addCase(getVoucherInCartAction.rejected, ($state) => {
      $state.isLoadingVoucherInCard = false;
    });
  }
});

export const {
  setStatisticsEvent, setStatusLiveStream, pushToOnLive, updateTotalOrder,
  setOrderSessionEvent, setVoucherSessionEvent, setVoucherInCart, setReCallVoucherInCart,
  setNeedUpdateLivestreamSessionCurrent, set404ForCombo
} = liveStreamSlice.actions;

export default liveStreamSlice.reducer;
