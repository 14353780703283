import React from 'react';

import endlivestreamImage from 'assets/images/endLivestream/Castrol PCO_Inter_Thông Báo-03.jpg';
import KvClip from 'components/templates/KvClip';

const EndLivestream: React.FC = () => (
  <KvClip
    list={[
      {
        src: endlivestreamImage,
        type: 'image',
      }
    ]}
  />
);

export default EndLivestream;
