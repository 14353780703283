import { useNavigate } from 'react-router-dom';

import { returnPath } from 'helpers/redirectToPage';
import { getTokenSession } from 'services/common/storage';
import { PageTypes, StatusLiveStreamTypes } from 'services/livestream/types';

type RedirectTypes = {
  status?: StatusLiveStreamTypes;
  page?: PageTypes;
  requireLogin?: boolean;
};

const useRedirectStatusCurrent = () => {
  const navigate = useNavigate();

  const redirectToLivestream = (params: RedirectTypes) => {
    const token = getTokenSession();
    if (token) {
      if (params.status === 'processing') {
        navigate(returnPath('LIVESTREAM'));
      } else if (params.status === 'simulator') {
        navigate(returnPath('SIMULATOR'));
      } else {
        navigate(returnPath('EVENT_WAITING'));
      }
    }
  };

  const redirectToStatusCurrent = (params: RedirectTypes) => {
    const token = getTokenSession();
    if (params.requireLogin && params.status !== 'processing' && !token) {
      navigate('/');
    } else if (params.page === 'end-livestream') {
      navigate(returnPath('END_LIVESTREAM'));
    } else if (params.status === 'processing') {
      navigate(returnPath('LIVESTREAM'));
    } else if (params.status === 'simulator') {
      navigate(returnPath('SIMULATOR'));
    } else if (params.status === 'pending' || params.status === 'stop') {
      navigate(returnPath('EVENT_WAITING'));
    }
  };

  return {
    redirectToStatusCurrent,
    redirectToLivestream
  };
};

export default useRedirectStatusCurrent;
