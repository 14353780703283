import axios from 'axios';

import { FoyerConfigData, LivestreamInteractivesData } from './type';

const getConfigsFoyerService = async (): Promise<FoyerConfigData> => {
  const res = await axios.get('configs/foyer.json', {
    headers: {
      'Cache-Control': 'no-cache',
    },
  });
  return res.data;
};

export const getConfigsLivestreamService = async (): Promise<LivestreamInteractivesData> => {
  const res = await axios.get('configs/livestream.json', {
    headers: {
      'Cache-Control': 'no-cache',
    },
  });
  return res.data;
};

export default getConfigsFoyerService;
