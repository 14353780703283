/* eslint-disable */
import React, { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Outlet, useNavigate } from 'react-router-dom';

import Loading from 'components/atoms/Loading';
import RedirectToPage, { returnPath } from 'helpers/redirectToPage';
import useResumeFg from 'hooks/useResumeFg';
import { getTokenSession } from 'services/common/storage';
import { pushEyeMemberService } from 'services/member';
import getTokenWebSocketService from 'services/websocket';
import socket from 'services/websocket/socket';
import { deleteComment, pushComment } from 'store/comment';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getLiveStreamCurrentAction,
  setNeedUpdateLivestreamSessionCurrent,
  setOrderSessionEvent, setStatisticsEvent,
  setStatusLiveStream, setVoucherSessionEvent
} from 'store/liveStream';
import { getMemberProfileAction } from 'store/profile';
import { getSystemAction, setResume, setSocketStatus } from 'store/system';
import useRedirectStatusCurrent from 'hooks/useRedirectStatusCurrent';

const MainLayoutContainer: React.FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const dispatch = useAppDispatch();
  const socketStatus = useAppSelector((state) => state.system.socketStatus);
  const liveStreamStatus = useAppSelector((state) => state.liveStream
    .liveStreamSessionCurrent?.status);
  const liveStreamCurrentSessionId = useAppSelector((state) => state.liveStream
    .liveStreamSessionCurrent?.id);
  const needUpdateLivestreamSessionCurrent = useAppSelector((state) => state.liveStream
    .needUpdateLivestreamSessionCurrent);

  const profile = useAppSelector((state) => state.profile.data);

  useEffect(() => {
    if (profile?.phone) {
      const { host, protocol } = window.location;
      const wsHost = `${protocol === 'https:' ? 'wss' : 'ws'}://${host}/ws`;
      // dev
      // const wsHost = 'wss://staging.sukiencastrol.com/ws';
      socket(
        wsHost,
        () => getTokenWebSocketService(),
        {
          onStateChange: (status) => {
            dispatch(setSocketStatus(status));
          },
          onMessage: (data: string) => {
            const parsedData = JSON.parse(data);
            switch (parsedData.event) {
              // Castrol
              case 'statistics':
                dispatch(setStatisticsEvent(parsedData.data));
                break;
              case 'liveStreamStatus':
              case 'liveStreamPageOrder':
              case 'liveStreamSurvey':
                dispatch(setStatusLiveStream(parsedData.data));
                break;
              case 'orderSessionStatus':
                dispatch(setOrderSessionEvent(parsedData.data));
                break;
              case 'requireLogin': // event 12h require login
                navigate('/');
                break;
              case 'newComment':
                dispatch(pushComment(parsedData.data));
                break;
              case 'deleteComment':
                dispatch(deleteComment(parsedData.data));
                break;
              case 'voucherSessionStatus':
                dispatch(setVoucherSessionEvent(parsedData.data));
                break;
              default:
                break;
            }
          },
        }
      );
    }
  }, [profile?.phone]);

  useQuery(
    ['pushEyeMember', socketStatus, liveStreamStatus],
    () => {
      if (liveStreamStatus === 'processing' && socketStatus === 2) {
        return pushEyeMemberService();
      }
      return null;
    }
  );

  const { redirectToStatusCurrent } = useRedirectStatusCurrent();

  const initSystem = () => {
    const token = getTokenSession();
    dispatch(getSystemAction())
      .unwrap()
      .then((res) => {
        if (res.statistics) {
          dispatch(setStatisticsEvent(res.statistics));
        }
      });
    dispatch(getLiveStreamCurrentAction())
      .unwrap()
      .then((data) => {
        redirectToStatusCurrent({
          status: data.status,
          page: data.page,
          requireLogin: data.requireLogin,
        });
      })
      .catch((error) => {
        if (error && Array.isArray(error) && error[0]?.field === 'start_date') {
          navigate(returnPath('EVENT_WAITING'));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    // liveStreamCurrentMutate();
    if (token) {
      dispatch(getMemberProfileAction());
    }
  };

  useEffect(() => {
    initSystem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When change session livestream
  useEffect(() => {
    if (liveStreamCurrentSessionId && needUpdateLivestreamSessionCurrent) {
      dispatch(setNeedUpdateLivestreamSessionCurrent(false));
      initSystem();
    }
  }, [liveStreamCurrentSessionId, needUpdateLivestreamSessionCurrent]);

  // const { mutate: trackingOnlineMuate } = useMutation(
  //   ['trackingOnlineUser'],
  //   (params: {
  //     id: number,
  //     paramsData: TrackingOnlineParamsTypes
  //   }) => trackingOnlineMemberService(params.id, params.paramsData)
  // );

  useResumeFg(
    () => {
      if (liveStreamCurrentSessionId && liveStreamStatus === 'processing') {
        queryClient.invalidateQueries('pushEyeMember');
        // trackingOnlineMuate({
        //   id: liveStreamCurrentSessionId,
        //   paramsData: {
        //     status: 'start',
        //   }
        // });
      }
      initSystem();
      dispatch(setResume(true));
    },
    () => {
      // if (liveStreamCurrentSessionId && liveStreamStatus === 'processing') {
      //   trackingOnlineMuate({
      //     id: liveStreamCurrentSessionId,
      //     paramsData: {
      //       status: 'end',
      //     }
      //   });
      // }
      dispatch(setResume(false));
    }
  );

  if (isLoading) return <Loading />;

  return (
    <main>
      <RedirectToPage />
      <Outlet />
    </main>
  );
};

export default MainLayoutContainer;
