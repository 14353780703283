import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import getConfigsFoyerService, { getConfigsLivestreamService } from 'services/configs';
import { FoyerConfigData, LivestreamInteractivesData } from 'services/configs/type';

interface ConfigsState {
  loading: boolean;
  foyer?: FoyerConfigData;
  interactives?: LivestreamInteractivesData;
}

const initialState: ConfigsState = {
  loading: false,
  foyer: undefined,
};

export const getConfigsFoyerAction = createAsyncThunk<
  FoyerConfigData,
  void,
  { rejectValue: ErrorResponse }
>(
  'configs/getConfigsFoyerAction',
  async (_, { rejectWithValue }) => {
    try {
      const configs = await getConfigsFoyerService();
      return configs;
    } catch (error) {
      return rejectWithValue(error as ErrorResponse);
    }
  },
);

export const getLivestreamInteractivesAction = createAsyncThunk<
  LivestreamInteractivesData,
  void,
  { rejectValue: ErrorResponse }
>(
  'configs/getLivestreamInteractivesAction',
  async (_, { rejectWithValue }) => {
    try {
      const configs = await getConfigsLivestreamService();
      return configs;
    } catch (error) {
      return rejectWithValue(error as ErrorResponse);
    }
  },
);

const configsReducer = createSlice({
  name: 'configsReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConfigsFoyerAction.fulfilled, ($state, action) => {
      $state.foyer = action.payload;
    });
    builder.addCase(getLivestreamInteractivesAction.fulfilled, ($state, action) => {
      $state.interactives = action.payload;
    });
  },
});

export default configsReducer.reducer;
