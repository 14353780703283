/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import eventConfigs from '../configs';

import useRedirectStatusCurrent from 'hooks/useRedirectStatusCurrent';
import { getTokenSession } from 'services/common/storage';
import { useAppSelector } from 'store/hooks';

export type KeyRoute = '/' | 'LIVESTREAM' | 'WAITING_1' | 'WAITING_2' | 'THANK_YOU' | 'REGISTER' | 'EVENT_WAITING' | 'SIMULATOR' | 'END_LIVESTREAM';

export type KeyMe = 'FOYER' | 'LIVESTREAM' | 'WAITING_1' | 'WAITING_2' | 'CME' | 'THANK_YOU';

// const token = getTokenSession();

export const returnPath = (code: KeyRoute): string => {
  const token = getTokenSession();
  switch (code) {
    case 'LIVESTREAM':
      return eventConfigs.SLUG_PARAMS.LIVESTREAM;
    case 'END_LIVESTREAM':
      return eventConfigs.SLUG_PARAMS.END_LIVESTREAM;
    case 'EVENT_WAITING':
      if (token) {
        return eventConfigs.SLUG_PARAMS.EVENTWAITING1;
      }
      return eventConfigs.SLUG_PARAMS.EVENTWAITING;
    // case 'WAITING_2':
    //   return eventConfigs.SLUG_PARAMS.LOGIN;
    case 'SIMULATOR':
      return eventConfigs.SLUG_PARAMS.SIMULATOR;
    case 'REGISTER':
      return eventConfigs.SLUG_PARAMS.HOME;
    default:
      return '/';
  }
};
export const returnKeyRoute = (path: string): string => {
  switch (path) {
    case eventConfigs.SLUG_PARAMS.LIVESTREAM:
      return 'LIVESTREAM';
    case eventConfigs.SLUG_PARAMS.HOME:
      return 'REGISTER';
    default:
      return '/';
  }
};

const RedirectToPage: React.FC = () => {
  const { redirectToStatusCurrent } = useRedirectStatusCurrent();
  const liveStreamStatus = useAppSelector((state) => state.liveStream.liveStreamSessionCurrent?.status);
  const page = useAppSelector((state) => state.liveStream.liveStreamSessionCurrent?.page);
  const requireLogin = useAppSelector((state) => state.liveStream.liveStreamSessionCurrent?.requireLogin);

  useEffect(() => {
    redirectToStatusCurrent({
      status: liveStreamStatus,
      page,
      requireLogin
    });
  }, [liveStreamStatus, page, requireLogin]);

  return null;
};

export default RedirectToPage;
