const SLUG_PARAMS = {
  HOME: '/',
  LIVESTREAM: '/livestream',
  LOGIN: '/dangnhap',
  REGISTER: '/xacnhan1',
  XACNHAN1: '/xacnhan1', // xacnhan2 -> xacnhan1
  WAITING: '/waiting',
  EVENTWAITING: '/',
  EVENTWAITING1: '/waiting1', // waiting2 -> waiting1
  WAITINGLIVESTREAM: '/waiting-livestream',
  BAO_MAT: '/baomat',
  LOGOUT: '/logout',
  SIMULATOR: '/simulator',
  END_LIVESTREAM: '/end-livestream',
};

export const foyerVideo = 'https://cdn.3forcom.org/static/ensure/hanh_trinh_ensure.3k.mp4';

export default {
  SLUG_PARAMS,
};
